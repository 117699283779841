<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                太平洋证券携手非凸科技，共同探讨交易创新与生态合作
              </p>
              <div class="fst-italic mb-2">2024年3月29日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >太平洋证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/78/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    3月29日，太平洋证券联合非凸科技以“科技赋能投资”主题在上海开展了行业交流活动，与私募管理人、资管机构负责人共同探讨了交易创新与生态合作新路径。
                  </p>
                  <p>
                    近年来，太平洋证券高度重视金融科技赋能财管转型，夯实金融科技底座，金融科技的发展为公司内部管理和外部展业起到了重要的支撑和促进作用。太平洋证券机构经纪管理系统平台搭载了极速交易柜台、极速行情、市场主流PB系统以及算法策略平台等全链路主经纪商服务满足私募客户、专业机构客户以及高净值个人客户的个性化需求。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/78/02-张廷轩.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    活动上，太平洋证券机构业务总经理张廷轩在致辞中表示，此次活动不仅是业务交流合作的好机会，更是共同应对未来挑战的重要契机。数智交易行业仍然具备广阔的发展空间，未来的发展将更加精细化。紧接着，张廷轩详细介绍了太平洋证券为私募管理人提供的综合服务解决方案，始终以客户需求为导向，致力于为客户提供优质的产品和完善的服务。为了满足各类投资者的交易需求，太平洋证券极速交易平台在交易、行情等多方面进行了系统优化，助力投资者致胜千里。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/78/03-郭晓龙.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，太平洋证券财富总部产品研究部负责人郭晓龙分享了私募产品的筛选条件及代销情况。他指出，未来私募产品的配置路线与发展方向将呈现多元化趋势。当前市场环境下，私募产品需要更加灵活地应对各种投资挑战和机会，以满足不同客户的需求。太平洋证券在准入制度方面的灵活性，为生态伙伴提供了更广泛的合作机会与支持。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/78/04-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    目前，非凸智能算法已成功上线太平洋证券，双方在高性能交易、高效率风控、高质量服务等方面展开了深度合作。非凸科技机构业务经理吴雨桐针对智能算法、交易终端以及合作模式等方面进行了详细介绍。她表示，新版本非凸客户端在多账户管理、算法交易、接入方式、绩效分析、分仓管理、增强交易等方面具备诸多优势，支持交易用户更敏捷、更流畅的算法资源管理与业务体验，可以为太平洋等券商机构交易服务品牌建设提供强有力的技术支撑。非凸科技追求极致的稳定与高效，以及真正专业的交易工具与服务，全面助力行业机构交易稳健发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/78/05-合影.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    交流环节，管理人与资方积极参与，就市场热点、算法表现、交易终端等话题展开了深入探讨，非凸科技对此也作出了细致解答。此外，他们还分享了丰富的业务背景与实战经验，为市场洞察增添了更多样的视角。
                  </p>
                  <p>
                    未来，非凸科技将加大金融信息技术自主创新，与行业伙伴持续深化合作，积极参与算法生态建设，为金融机构提供真正满足数智化转型需求的应用产品和解决方案，以技术创新推动行业发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News78",
};
</script>

<style></style>
